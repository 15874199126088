@import '../../../antd-theme.less';

.multiWrapper {
	margin-bottom: 20px;
	box-shadow: none;
	& > :global(.ant-card-body) {
		// padding: 10px 15px;
		// padding-bottom: 0;
		padding: 0;
	}
}

@media (min-width: @screen-md-min) {
	.multiWrapper {
		margin-bottom: 30px;
		& > :global(.ant-card-body) {
			// padding: 15px 20px;
			// padding-bottom: 0;
		}
	}
}
