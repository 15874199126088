@import '../../antd-theme.less';

.theme {
	color: @text-color-secondary-dark;
	margin: 0 -10px;
	padding: 40px;
	border: solid 2px #2d2d2d;
	border-left-width: 0;
	border-right-width: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.themeSwitch {
	height: 40px;
	min-width: 74px;
	background-color: @q-gray-2;

	:global {
		.ant-switch-handle {
			width: 34px;
			height: 34px;
			top: 3px;
			left: 3px;
			&:before {
				border-radius: 17px;
			}
		}

		.ant-switch-inner {
			position: absolute;
			top: 12px;
			left: 12px;
			margin: 0;
			transition: transform 0.2s;
		}
	}

	&:global(.ant-switch-checked) :global {
		.ant-switch-handle {
			left: calc(100% - 34px - 3px);
			&:before {
				background-color: @q-black;
			}
		}

		.ant-switch-inner {
			transform: translateX(35px);
		}
	}
}
