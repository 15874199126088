@import '../../antd-theme.less';

.header {
	margin-bottom: 10px;
	display: flex;
	white-space: nowrap;
	flex-wrap: wrap;
	align-items: center;
}

.label {
	margin-right: 10px;

	&_fill {
		flex-grow: 1;
	}
}

.selectWrapper {
	flex: 1 0 300px;
	overflow: hidden;
}

.select {
	max-width: 100%;
}

@media (min-width: @screen-md-min) {
	.header {
		min-height: 52px;
	}
}
