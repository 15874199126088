@import '../../../antd-theme.less';

.card {
	font-size: 14px;
	&:not(:last-child) {
		margin-bottom: 10px;
	}
}

.ticker {
	font-weight: bold;
}

.row1 {
	display: flex;
	justify-content: space-between;
	column-gap: 10px;
}

.row2 {
	display: flex;
	padding: 9px 0 2px;
}

.formatIcon {
	flex: 0 0 calc(100% / 3);
	display: flex;
	align-items: center;

	&_1 {
		justify-content: center;
	}
	&_2 {
		justify-content: flex-end;
	}
}

.sep {
	margin: 0 8px 0 10px;
}

.downloadLink {
	svg {
		position: relative;
		top: 2px;
		vertical-align: top;
	}
}

.date {
	font-weight: normal;
	color: @q-gray-2;
}

.qty {
	font-weight: 500;
}

.props {
	height: 0;
	overflow: hidden;
	transition: 0.2s height;
	margin: 0 -15px 0;
	background: @card-background;

	&_expand {
		height: auto;
	}
}

.propsInner {
	margin-top: 15px;
	border-top: solid 1px @body-background;
	padding: 0 15px;
}

.prop {
	display: flex;
	justify-content: space-between;
	margin-top: 11px;
}

.label {
	color: @q-gold;
}

:global(body.dark) {
	@import '../../../antd-theme-dark.less';

	.props {
		background: @card-background;
	}

	.propsInner {
		border-top: solid 1px @body-background;
	}

	.date {
		color: @text-color;
		opacity: 0.7;
	}
}
