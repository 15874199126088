@import '../../../antd-theme.less';

.card :global(.ant-card-body) {
	display: flex;
	flex-direction: column;
	padding: 20px 15px;
}

.controls {
	display: flex;
	justify-content: space-between;

	&_narrow {
		// justify-content: center;
		flex-wrap: wrap-reverse;
		row-gap: 10px;
	}
}

.chartArea {
	min-height: 400px;
	flex-grow: 1;
}

@media (min-width: @screen-md-min) {
	.card :global(.ant-card-body) {
		padding: 30px;
	}
}
