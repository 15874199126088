@import '../../../antd-theme.less';

.card :global(.ant-card-body) {
	display: flex;
	padding: 20px 15px;
	flex-direction: column;
}

.chartArea {
	flex-grow: 1;
	height: 275px;
}

@media (min-width: @screen-md-min) {
	.card :global(.ant-card-body) {
		padding: 30px;
	}
}
