/**
 * This file combines Less variables ONLY: antd "default" theme + our brand overrides
 * IMPORT this file in component styles to use the variables
 */

@import '~antd/lib/style/themes/default.less';
@import './antd-theme-common-vars.less';

@body-background: #f4f5fa;
@component-background: #f4f5fa;

@text-color: @q-black;
@heading-color: @q-black;

@card-background: #fff;

@input-bg: #fff;
@input-color: @q-gray-2;

@radio-button-color: @q-gray-2;
@radio-button-group-border-color: #ebedf3;

@select-border-color: #ebedf3;
@select-color: @q-gray-2;

.qp-scroll() {
	&::-webkit-scrollbar {
		width: 15px;
		height: 15px;
	}
	&::-webkit-scrollbar-track {
		border: solid 6px transparent;
		border-radius: 8px;
		background-clip: content-box;
		background-color: rgba(209, 143, 31, 0.3);

		&:vertical {
			border-top: none;
			border-bottom: none;
		}

		&:horizontal {
			border-left: none;
			border-right: none;
		}
	}
	&::-webkit-scrollbar-thumb {
		border: solid 6px transparent;
		background-clip: content-box;
		background-color: @q-gold;
		border-radius: 8px;

		&:vertical {
			border-top: none;
			border-bottom: none;
		}

		&:horizontal {
			border-left: none;
			border-right: none;
		}
	}
	scrollbar-color: @q-gold rgba(209, 143, 31, 0.3);
}
