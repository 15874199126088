@import '../../antd-theme.less';

.container {
	font-size: 14px;
	display: flex;
	align-items: center;
	white-space: nowrap;
}

.icon {
	margin-right: 10px;
}

.hint {
	position: relative;
	top: 1px;
}

@media (min-width: @screen-md-min) {
	.container {
		font-size: 16px;
	}
}
