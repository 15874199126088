@import '../../../antd-theme.less';

.footer {
	position: fixed;
	bottom: 0;
	left: 0;
	flex: 0 0 auto;
	padding: 8px 20px 16px;
	height: auto;
	line-height: normal;
	justify-content: space-between;
	display: flex;
	width: 100%;
	z-index: 1000;
	background: @q-black;
}

.item {
	padding: 0;
	flex: 0 0 20%;
	width: 20%;
	height: auto;
	font-size: 10px;
	color: @text-color-secondary-dark;

	&_active,
	&:active,
	&:hover,
	&:focus {
		color: @primary-color;
	}
}

.itemIcon {
	svg {
		height: 20px;
	}
}

.menu {
	background-color: @q-black;
	border-radius: 10px;
	padding: 0 0 5px;
	margin: 0 20px;
}

.menuItem {
	font-size: inherit;
	width: 100%;
	display: block;
	padding: 20px 20px 15px;
	line-height: 20px;
	text-align: left;

	& > svg {
		margin-right: 15px;
		height: 20px;
		vertical-align: top;
	}

	&:not(:last-child) {
		border-bottom: solid 1px #2d2d2d;
	}
}
