@import '../../../antd-theme.less';

.container {
	// max-width: none;
	flex-grow: 1;
}

.widget {
	margin-bottom: 30px;
}

.col {
	// width: 25%;
	// min-width: 120px;
}

.card {
	height: 100%;
	justify-content: flex-end;
}

.c2 {
	text-overflow: ellipsis;
}

@media (min-width: @screen-md-min) {
	.col {
		// min-width: 160px;
	}
	.container {
		min-width: 640px;
	}
}

@media (min-width: @screen-xl-min) {
	.col {
		// min-width: 190px;
	}
	.container {
		min-width: 760px;
	}
}
