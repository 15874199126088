@import '../../antd-theme.less';

.select:global(.ant-select) {
	font-size: 32px;
	color: @primary-color;

	:global {
		.ant-select-selector {
			padding-left: 0;
			height: auto;
		}

		.ant-select-arrow {
			color: @primary-color;
		}

		.ant-select-selection-item {
			line-height: inherit;
		}
	}
}

.client {
	&:global(.ant-select.ant-select-single:not(.ant-select-customize-input)) {
		margin-bottom: 10px;
		color: @q-gold;

		:global {
			.ant-select-selector {
				padding: 17px 20px 15px;
				height: 50px;
				border: none;
				font-size: 15px;
				background: @q-gray-2;
			}

			.ant-select-arrow {
				color: @q-gold;
			}

			.ant-select-selection-item {
				line-height: 18px;
				color: @q-gold;
			}
		}
	}

	&:global(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)) {
		:global {
			.ant-select-selector {
				color: @q-gray;
				box-shadow: none;
			}
		}
	}

	&:global(.ant-select-disabled) {
		:global {
			.ant-select-selector {
				cursor: default;
				color: @q-gray;
			}

			.ant-select-arrow {
				display: none;
			}
		}
	}
}

.clientDropdown {
	background: @q-gray-2;
	border-radius: 0 0 4px 4px;
	box-shadow: none;

	:global {
		@padding-h: 20px;

		.ant-select-item.ant-select-item-option {
			font-size: 15px;
			padding: 15px @padding-h;
			font-weight: 600;
			color: @q-gray;

			&:not(:last-child) {
				margin-bottom: 1px;

				&:after {
					content: '';
					display: block;
					border-bottom: solid 1px rgba(45, 45, 45, 0.42);
					position: absolute;
					bottom: -1px;
					left: @padding-h;
					right: @padding-h;
				}
			}

			&.ant-select-item-option-selected {
				background-color: @q-gray-2;
				color: @q-gray;
			}

			&.ant-select-item-option-active {
				background-color: rgba(255, 255, 255, 0.08);
			}
		}
	}
}
