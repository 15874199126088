:global(.ant-table .ant-table-tbody > tr > td).fileFormatCol {
	padding-top: 0;
	padding-bottom: 0;
}

.fileFormat {
	&:not(:last-child) {
		margin-right: 12px;
	}

	& > svg,
	&Spinner {
		vertical-align: top;
	}
}
