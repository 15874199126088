@import '../../../antd-theme.less';

.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 50px;
	padding: 0 20px;
	line-height: normal;
	justify-content: space-between;
	align-items: center;
	display: flex;
	z-index: 1000;
}

.logo {
	width: 130px;
}

.menuBtn {
	color: @q-gold;
	padding: 0;
	height: 20px;
	border: none;

	img {
		vertical-align: top;
	}
}
