@import '../../antd-theme.less';

.layout {
	height: 100vh;
}

.header {
	align-items: center;
	margin-bottom: 30px;
}

h1.pageTitle:global(.ant-typography) {
	margin-bottom: 0;
}

.search {
	width: 280px;

	svg {
		color: @q-gold;
	}
}

.anchors {
	margin-bottom: 40px;
	display: none;
	grid-template-columns: repeat(auto-fill, minmax(28px, 1fr));
	gap: 5px 7px;
}

.anchor {
	background: #fff;
	color: @q-black;
	font-size: 21px;
	line-height: 25px;
	width: 28px;
	height: 28px;
	padding: 0;
	font-weight: 700;
	border: none;
	border-radius: 3px;

	&_active {
		color: #fff;
		background-color: @q-gold;
	}

	&:disabled {
		color: fade(@q-black, 20%);

		&:hover {
			border: none;
			background: #fff;
		}
	}
}

.content {
	.qp-scroll();
	height: 100%;
	overflow: auto;
}

.term {
	color: @q-gray;
	font-size: 15px;
	line-height: 24px;

	&:not(:last-child) {
		margin-bottom: 37px;
	}
}

.termTitle {
	color: #000;
	font-size: 21px;
	font-weight: 700;
	margin-bottom: 12px;
}

:global(body.dark) {
	@import '../../antd-theme-dark.less';

	.termTitle {
		color: @q-gray;
	}

	.anchor {
		background-color: @card-background;
		color: @q-gray;

		&_active {
			color: #fff;
			background-color: @q-gold;
		}

		&:disabled {
			color: fade(@q-gray, 40%);

			&:hover {
				background-color: @card-background;
			}
		}
	}
}

@media (min-width: @screen-md-min) {
	.anchors {
		display: grid;
	}
	.card {
		overflow: hidden;

		:global {
			.ant-card-body {
				height: 100%;
			}
		}
	}
}

@media (min-width: @screen-xl-min) {
	.header {
		align-items: center;
		margin-bottom: 50px;
	}

	.anchors {
		margin-bottom: 60px;
		grid-template-columns: repeat(auto-fill, minmax(42px, 1fr));
		gap: 5px 20px;
	}

	.anchor {
		font-size: 21px;
		line-height: 25px;
		width: 42px;
		height: 42px;
	}
}
