@import '../../../antd-theme.less';

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: background-color 0.3s ease;
	pointer-events: none;
	background-color: rgba(35, 35, 35, 0);
	z-index: 1002;

	&_visible {
		background-color: rgba(35, 35, 35, 0.85);
		pointer-events: initial;
	}
}

.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	width: 80vw;
	height: 100%;
	transform: translateX(-80vw);
	transition: transform 0.3s ease;
	background-color: @layout-sider-background;
	z-index: 1002;
	padding: 40px 20px;

	&_visible {
		transform: translateX(0);
	}
}

.client {
	width: 100%;
}

.theme {
	padding: 10px 0;
	border-top: none;
	margin: 0;
}

.logout {
	color: @text-color-secondary-dark;
	display: block;
	padding: 0;
	margin-top: 20px;
	line-height: 20px;

	svg {
		vertical-align: top;
		margin-right: 15px;
	}
}
