@import '../../../antd-theme.less';

.card {
	height: 100%;
	justify-content: flex-end;
}

.mobileCard {
	:global(.ant-card-body) {
		padding: 0;
	}
}

.row {
	&_mobile {
		flex-wrap: nowrap;
		white-space: nowrap;
	}
}

.widget {
	&_mobile {
		overflow-x: auto;
		.qp-scroll();
	}
}
