@import '../../antd-theme.less';

.card {
	display: flex;
	flex-direction: column;
	line-height: 1.3;
}

.listItem {
	display: flex;
	padding: 15px 20px;
	border-bottom: 1px solid #f4f5fa;

	// &:first-child {
	// padding-top: 23px;
	// }

	&:last-child {
		padding-bottom: 18px;
		border-bottom: none;
	}
}

.listItemContent {
	display: flex;
	flex-direction: column;
}

.valueRow {
	margin: 20px 0 9px;
}

.value {
	font-weight: 700;
	font-size: 20px;

	&_mobile {
		line-height: 26px;
	}
}

.title {
	color: @q-gray;
	font-size: 15px;

	&_mobile {
		font-size: 14px;
	}
}

.title,
.value {
	overflow: hidden;
	text-overflow: ellipsis;
}

@media (min-width: @screen-md-min) {
	.value {
		font-size: 24px;
	}
}

@media (min-width: @screen-xl-min) {
	.value {
		font-size: 32px;
	}
	.title {
		font-size: 17px;
	}
}

:global(body.dark) {
	.value {
		color: @q-gold;
	}

	.listItem:not(:last-child) {
		border-bottom: solid 2px #2d2d2d;
	}
}

.icon {
	margin-right: 15px;
}

.track {
	position: relative;
	height: 16px;
	background: rgba(231, 208, 173, 0.25);
	border-radius: 3px;
	margin: 6px 0 20px;

	&Piece {
		height: 100%;
		position: absolute;
		border-radius: 3px;
	}

	&Labels {
		display: flex;
		column-gap: 10px;
		margin: 0 -10px;
	}

	&Label {
		overflow: visible;
		padding: 0 10px;
	}
}
