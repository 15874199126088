.search {
	margin-bottom: 20px;
	width: 280px;
}

.card {
	:global(.ant-card-body) {
		max-height: 500px;
		overflow: auto;
	}

	:global(.ant-table-wrapper) {
		height: auto;
	}
}
