@import '../../antd-theme.less';

.item {
	position: relative;
	font-size: 15px;
	padding: 0 20px;
	color: @q-gray-2;
	white-space: nowrap;
	transition: opacity 0.25s ease;
	display: inline-block;

	&_hidden {
		opacity: 0.4;
	}
}

.icon {
	position: absolute;
	top: -4px;
	left: -6px;
	transform: rotate(90deg);
}

:global(body.dark) {
	.item {
		color: @q-gray;
	}
}
