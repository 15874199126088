.layout {
	height: 100vh;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;

	&:global(.ant-layout-content) {
		padding: 0;
	}
}
