/**
 * Antd style overrides that are common for all themes.
 * You can use antd vars here and they will represent correct colors according to the selected theme.
 */

.ant-card {
	box-shadow: 0px 0px 20px rgba(105, 171, 202, 0.1);
}

:where(body.dark) {
	.ant-card {
		box-shadow: 0px 0px 30px 0px rgba(34, 34, 34, 0.051);
	}
}

.ant-card-body {
	@media (max-width: @screen-sm-max) {
		padding: 15px;
	}
}

h1.ant-typography,
.ant-typography h1 {
	@media (min-width: @screen-md-min) {
		font-size: 32px;
	}
}

h2.ant-typography,
.ant-typography h2 {
	@media (min-width: @screen-md-min) {
		font-size: 24px;
	}
}

.ant-input,
.ant-input-affix-wrapper {
	border-radius: 4px;
	font-size: 15px;
	border: none;
	padding: 13px 20px 12px;
	line-height: 25px;
}

.ant-select {
	font-size: 15px;
	color: @select-color;

	&-item {
		font-size: 15px;
	}

	&:not(&-customize-input) &-selector {
		border-radius: 4px;
	}
}

.ant-picker-panel-container {
	border-radius: 4px;
	font-size: 14px;

	.ant-picker-date-panel .ant-picker-date-panel {
		width: 300px;

		.ant-picker-content th {
			width: 41px;
		}

		.ant-picker-body {
			padding: 8px 5px;
		}
	}
}

.ant-radio-button-wrapper {
	height: 36px;
	font-size: 15px;
	border-color: @radio-button-group-border-color;
	padding: 3px 20px 0;

	&-checked {
		&:not(.ant-radio-button-wrapper-disabled) {
			&,
			&:hover,
			&:active {
				color: @radio-button-group-checked-color;
			}
		}
	}

	&:first-child {
		border-left: 1px solid @radio-button-group-border-color;
		border-radius: @radio-button-group-border-radius 0 0 @radio-button-group-border-radius;
	}

	&:last-child {
		border-right: 1px solid @radio-button-group-border-color;
		border-radius: 0 @radio-button-group-border-radius @radio-button-group-border-radius 0;
	}

	&:first-child:last-child {
		border-radius: @radio-button-group-border-radius;
	}

	&:not(:first-child)::before {
		background: @radio-button-group-border-color;
	}
}

:where(body.dark) {
	.ant-radio-button-wrapper {
		&:not(:first-child)::before {
			background: @card-background;
		}
	}
}

.ant-tooltip {
	font-size: 15px;
}
