.spinner {
	animation: spin 1.5s linear infinite;
	transform-origin: 37.5px 47.5px;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}
