@import '../../antd-theme.less';

.topPanel {
	flex: 0 0 auto;
	background: @body-background;
	z-index: 5;
	padding: 20px 20px 0 20px;
}

.bottomPanel {
	overflow: auto;
	padding: 0 20px 20px 20px;
}

.carousel {
	width: 100vw;
	margin: 0 -20px;
	overflow: auto hidden;
	scroll-snap-type: x mandatory;
}

.panels {
	display: flex;
	flex-wrap: nowrap;
	column-gap: 10px;
	padding: 0 20px;
}

.panel {
	flex-shrink: 0;
	flex-grow: 0;
	scroll-snap-align: center;
}

.header {
	position: relative;
	margin-bottom: 30px;
	font-size: 20px;
	color: #fff;
	border-radius: 10px;

	:global(.ant-card-body) {
		padding: 20px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: '';
		background: url('../../images/card-bg.svg');
		mix-blend-mode: multiply;
		pointer-events: none;
	}
}

.productTitle {
	height: 57px;
	line-height: 24px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.productDetails {
	font-size: 15px;
	height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.productInception {
	display: flex;
	align-items: center;
	margin-right: 15px;
}

.productInceptionIcon {
	margin-right: 14px;
}

.productType {
	overflow: hidden;
	text-overflow: ellipsis;
}

.productTypeLabel {
	opacity: 0.5;
}

:global(body.dark) {
	@import '../../antd-theme-dark.less';

	.topPanel {
		background: @body-background;
	}
}
