@import '../../antd-theme.less';

.cover {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	height: 100%;
}

.icon {
	margin-bottom: 20px;
}

.noDataIcon {
	color: @q-gray;
	font-size: 64px;
}

.title {
	margin-bottom: 9px;
}

.subtitle {
	font-size: 15px;
	color: @q-gray;
}

.error {
	color: #d64444;
}
