@import '../../../antd-theme.less';

.multiWrapper {
	margin-bottom: 20px;
	box-shadow: none;
	& > :global(.ant-card-body) {
		// padding: 10px 15px;
		// padding-bottom: 0;
		padding: 0;
	}
}

.widget {
	margin-bottom: 20px;

	&_multi {
		margin-top: 15px;
		margin-bottom: 15px;
	}
}

.widgetWithSep:not(:last-child) {
	// margin: 0 -15px 10px;
	padding: 0 15px;
	border-bottom: solid 1px #f4f5fa;
}

.headerRow {
	align-items: center;
	margin-bottom: 10px;
}

h2.title:global(.ant-typography) {
	margin: 0;
}

.col {
	// width: 20%;
	// min-width: 127px;
	overflow: hidden;
}

.card {
	height: 100%;
	justify-content: flex-start;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: right;

	&_multi {
		background: #eeeff4;
		border: solid 1px rgba(255, 255, 255, 0.2);
	}

	:global(.ant-card-body) {
		padding: 15px 20px;
	}

	&_padding20 :global(.ant-card-body) {
		padding: 20px;
	}

	&_padding15 :global(.ant-card-body) {
		padding: 15px;
	}
}

.valueRow {
	&_font20 {
		font-size: 20px;
	}
	&_font24 {
		font-size: 24px;
	}
	&_font32 {
		font-size: 32px;
	}
}

.cardValue {
	margin: 4px 0 2px;
}

@media (min-width: @screen-md-min) {
	.multiWrapper {
		margin-bottom: 30px;
		& > :global(.ant-card-body) {
			// padding: 15px 20px;
			// padding-bottom: 0;
		}
	}

	.widget {
		margin-bottom: 30px;
		&_multi {
			margin-bottom: 15px;
		}
	}

	.widgetWithSep:not(:last-child) {
		// margin: 0 -20px 15px;
		padding: 0 20px;
	}
	.headerRow {
		margin-bottom: 15px;
	}
	.col {
		// min-width: 150px;
	}
}

@media (min-width: @screen-xl-min) {
	.col {
		// min-width: 178px;
	}
}

:global(body.dark) {
	.card {
		&_multi {
			background: #28292c;
			border-color: transparent;
		}
	}

	.widgetWithSep:not(:last-child) {
		border-bottom: solid 2px #2d2d2d;
	}

	.widgetWithSep:not(:first-child) .widget {
		margin-top: 14px;	// compensate border increased from 1px to 2px
	}
}

.titleWithInfo {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	&Text {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&Icon {
		margin-left: 5px;
	}
}
