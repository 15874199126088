.header {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
}

h2.title:global(.ant-typography) {
	margin-bottom: 0;
}

.addon {
	flex-grow: 1;
	text-align: right;
	margin-left: 10px;
}
