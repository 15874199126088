@import '../../../antd-theme.less';

.search {
	margin-bottom: 20px;
	width: 100%;

	:global(.anticon) {
		color: @q-gold;
	}
}

@media (min-width: @screen-md-min) {
	.search {
		width: 280px;
	}
}
