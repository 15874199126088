@import './antd-theme.less';

.layout {
	overflow: hidden;
	height: 100vh;

	&_mobile {
		padding: 50px 0 65px;
	}
}

.content {
	overflow: auto;
	display: flex;
	flex-direction: column;

	:global(.ant-layout-content) {
		/* mobile: fill 100% height */
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 20px 20px 50px 20px;
	}
}

@media (min-width: @screen-md-min) {
	.content {
		display: block;
		:global(.ant-layout-content) {
			padding-left: 30px;
			padding-right: 24px;
		}
	}
}

@media (min-width: @screen-xl-min) {
	.content :global(.ant-layout-content) {
		padding-right: 28px;
	}
}
