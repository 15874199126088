@import '../../../antd-theme.less';

.card :global(.ant-card-body) {
	display: flex;
	padding: 20px 15px;
	flex-direction: column;
}

.chartArea {
	flex-grow: 1;
	height: 350px;
}

.summary {
	display: flex;
	justify-content: space-between;
	color: @q-gold;
}

@media (min-width: @screen-md-min) {
	.card :global(.ant-card-body) {
		padding: 30px;
	}
}
