@import '../../../antd-theme.less';

.widget {
	margin-bottom: 30px;
}

.col {
	width: calc(100% / 3);
}

.card {
	height: 100%;
	justify-content: flex-end;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media (min-width: @screen-md-min) {
	.container {
		min-width: 640px;
	}
}

@media (min-width: @screen-xl-min) {
	// .container {
	// min-width: 573px;
	// }
}
