@import '../../../antd-theme.less';

.container {
	position: relative;
}

.cog {
	position: absolute;
	top: -50px;
	right: 0;
	cursor: pointer;
}

.inputsWrapper {
	max-height: 100vh;
	transition: max-height 0.3s ease;
	overflow: hidden;

	&_collapsed {
		max-height: 0;
	}
}

.inputs {
	align-items: flex-start;
	padding-bottom: 30px;
}

.label {
	font-size: 15px;
	margin-bottom: 2px;
}

.col:not(:last-child) {
	margin-right: 20px;
}

.search {
	margin-bottom: 10px;
	width: 250px;

	:global(.anticon) {
		color: @q-gold;
	}
}

.dateRange {
	min-height: 50px;
	margin-bottom: 10px;
	padding-top: 9px;
	justify-content: flex-start;

	&Preset {
		text-align: left;
		flex-grow: 0;
	}
}

.products {
	margin-bottom: 10px;

	:global(.ant-select-selector) {
		padding: 10px 17px 11px;
		background: #fff;
		border: none;
		min-width: 300px;
		max-width: 600px;
	}

	:global(.ant-select-selection-search) {
		margin: 3px 0;
	}

	:global(.ant-select-selection-item) {
		margin: 2px 4px 2px 0;
	}

	:global(.ant-select-selector::after) {
		margin: 3px 0;
	}
}

@media (min-width: @screen-md-min) {
	top: -55px;
}

:global(body.dark) {
	@import '../../../antd-theme-dark.less';

	.products {
		:global(.ant-select-selector) {
			background: @input-bg;
			border: none;
		}
	}
}
