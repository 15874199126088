@import '../../../antd-theme.less';

.widget {
	flex: 0 0 100%;
	width: 100%;
}

.col {
	// min-width: 120px;
}

.card {
	height: 100%;
}

@media (min-width: @screen-md-min) {
	.col {
		//min-width: 160px;
	}
}

@media (min-width: @screen-xl-min) {
	.col {
		// min-width: 190px;
	}
}
