.widget {
	overflow: hidden;
	height: 100%;
	display: flex;
	flex-direction: column;

	&_stretch {
		:global {
			.ant-card,
			.ant-card-body {
				height: 100%;
			}
		}
	}
}

.content {
	flex-grow: 1;
}
