@import '../../../antd-theme.less';

.container {
	display: flex;
	gap: 20px 40px;
	flex-wrap: wrap;
}

.icon {
	display: inline-block;
}

.item {
	display: flex;
	flex-wrap: nowrap;
	white-space: nowrap;
	align-items: center;
}

.value {
	margin: 0 10px 0 15px;
	line-height: 20px;
}

.label {
	color: @q-gray;
	margin-bottom: 5px;

	& :global(.anticon.anticon-info-circle) {
		vertical-align: middle;
	}
}

:global(body.dark) {
	.figure {
		color: @q-gold;
	}
}
