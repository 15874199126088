.timePeriod {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;

	:global(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
		padding: 0 10px;
	}
}

.preset {
	flex-grow: 1;
	text-align: right;
}
