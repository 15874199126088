@import '../../../antd-theme.less';

.container {
	max-height: 50px;

	&_padded {
		padding: 10px 0;
	}
}

.loading {
	justify-content: flex-start;
}

.card {
	background: @q-gray-2;
	width: 100%;

	:global(.ant-card-body) {
		text-align: center;
		color: @q-gray;
	}
}

.popover :global {
	width: 100%;

	.ant-popover-inner {
		background: none;
	}

	.ant-popover-inner-content {
		padding: 0;
	}

	.ant-popover-arrow {
		border-right-color: @q-gray-2;
		border-bottom-color: @q-gray-2;
	}
}

.email,
.phone {
	font-size: 15px;
	line-height: 18px;
	opacity: 0.3;
	padding-top: 5px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.emailLink,
.phoneLink {
	color: @q-gray;

	&:hover {
		color: @q-gold;
	}
}

.icon {
	margin: 0 auto;
}

.iconWrap {
	width: 50px;
	margin: 0 auto;
}
