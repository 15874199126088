@import '../../../antd-theme.less';

.row {
	display: flex;
	font-size: 15px;

	&:not(:last-child) {
		margin-bottom: 10px;
	}
}

.date {
	white-space: nowrap;
	text-align: center;
	width: 40px;
	margin-right: 10px;
}

.month {
	line-height: 1;
}

.day {
	font-weight: 700;
	font-size: 20px;
	line-height: 1;
}

.weekDay {
	display: none;
}

.events {
	flex: 1;
	position: relative;
	top: -4px;
}

.event:not(:last-child) {
	margin-bottom: 5px;
}

.noEvents {
	opacity: 0.5;
}

@media (min-width: @screen-md-min) {
	.date {
		width: 40px;
		margin-right: 15px;
	}

	.day {
		font-size: 24px;
	}
}

@media (min-width: @screen-xl-min) {
	.row {
		font-size: 17px;
	}
	.date {
		width: 50px;
		margin-right: 20px;
	}
	.day {
		font-size: 32px;
	}
	.events {
		top: -5px;
	}
}

.horizontal {
	:global(.ant-card-body) {
		display: flex;
		flex-direction: row;
		padding: 0;
		height: auto;
		min-height: 100%;
	}

	.row {
		flex-direction: column;
		flex: 0 0 calc(100% / 7);
		padding: 0;
		margin: 0;
		&:not(:last-child) {
			border-right: solid 1px @table-border-color;
		}
	}

	.date {
		padding: 10px 0;
		line-height: 18px;
		text-align: center;
		color: @q-gold;
		border-bottom: solid 1px @table-border-color;
		width: 100%;
		position: sticky;
		top: 0;
		background-color: @card-background;
		z-index: 1;
	}

	.events {
		padding: 5px 10px 20px;
	}

	.noEvents,
	.event {
		padding-top: 8px;
		line-height: 18px;
	}

	@media (min-width: @screen-xl-min) {
		.row {
			font-size: 15px;
		}
	}
}

:global(body.dark) {
	@import '../../../antd-theme-dark.less';

	.horizontal {
		.row {
			&:not(:last-child) {
				border-right: solid 1px @table-border-color;
			}
		}

		.date {
			background-color: @card-background;
			border-color: @table-border-color;
		}
	}
}
