@import '../../antd-theme.less';

.picker :global {
	font-size: 15px;
	color: @q-gold;

	.ant-picker-input > input {
		font-size: inherit;
		color: inherit;
	}
}

.mobileRange {
	line-height: 32px;
	font-size: 15px;
	color: @q-gold;
}

.mobilePicker {
	font-size: inherit;
	color: inherit;
	padding: 2px 0;

	:global {
		.ant-picker-input > input {
			font-size: inherit;
			color: inherit;
			width: 100px;
			text-align: center;
		}
	}

	&_from :global {
		.ant-picker-input {
			flex-direction: row-reverse;
		}

		.ant-picker-suffix {
			color: inherit;
			margin: 0 10px 0 0;
			line-height: 1.2;
		}
	}
}
