@import '../../antd-theme.less';

.sticky {
	overflow: visible;
}

.card {
	height: 100%;
	overflow: auto;

	&.sticky {
		overflow: visible;
	}

	:global(.ant-card-body) {
		padding: 0;
		height: 100%;
	}
}

.table {
	:global {
		&,
		.ant-spin-nested-loading,
		.ant-spin-container,
		.ant-table,
		.ant-table-container {
			height: 100%;
		}

		.ant-table-content {
			height: 100%;
			overflow: auto;
		}

		.ant-table-cell-fix-left,
		.ant-table-cell-fix-right {
			background-color: #fff;
			overflow: visible;
		}

		.ant-table-thead > tr > th {
			background-color: #fff;
		}

		.ant-table-column-sorter {
			display: none;
		}

		.ant-table-column-sort {
			.ant-table-column-sorter {
				display: block;
			}
			.ant-table-column-sorter-inner {
				width: 12px;
			}
		}

		.ant-table-column-sorter-up {
			&.active:before {
				content: '';
				display: inline-block;
				width: 12px;
				height: 6px;
				background: url('../../icons/table-sorter.svg') no-repeat;
				position: relative;
				top: -2px;
			}
			svg {
				display: none;
			}
		}
		.ant-table-column-sorter-down {
			&.active:before {
				content: '';
				display: inline-block;
				width: 12px;
				height: 6px;
				background: url('../../icons/table-sorter.svg') no-repeat;
				transform: rotate(180deg);
				position: relative;
				top: -3px;
			}
			svg {
				display: none;
			}
		}
	}
}

.col {
	line-height: 18px;
	white-space: nowrap;

	th& {
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: keep-all;
	}
}

:global(body.dark) {
	@import '../../antd-theme-dark.less';

	.table {
		:global {
			.ant-table-cell-fix-left,
			.ant-table-cell-fix-right {
				background-color: @card-background;
			}

			.ant-table-thead > tr > th {
				background-color: @card-background;
			}
		}
	}
}
