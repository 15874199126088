@import '../../antd-theme.less';

.sider {
	height: 100vh;
	overflow: hidden auto;

	:global(.ant-layout-sider-children) {
		padding: 40px 10px 0;
		display: flex;
		flex-direction: column;
	}
}

.logo {
	text-align: center;
	padding-bottom: 40px;
}

.menu {
	:global(.ant-menu-item) {
		padding: 20px 0 20px 30px;
		margin-top: 0;
		height: 60px;
		line-height: 20px;
		border-radius: 4px;

		&:not(:last-child) {
			margin-bottom: 0;
		}
	}
}

.itemIcon {
	height: 20px;
	width: 23px;
	margin-right: 16px;
	vertical-align: top;
}

.logoutPanel {
	text-align: center;
	padding: 10px 0 40px;
}

.logoutBtn {
	&,
	&:focus {
		&,
		& svg {
			color: @text-color-secondary-dark;
		}
	}

	&:hover,
	&:active {
		&,
		& svg {
			color: @primary-color;
		}
	}

	svg {
		margin-right: 15px;
		vertical-align: text-bottom;
		transition: color 0.3s @ease-in-out;
	}
}

.spacer {
	flex-grow: 1;
}

.contact {
	padding: 20px 0 10px;
}

:global(body.dark) {
	.menu {
		background: @q-black;
	}
}
