@import '../../../antd-theme.less';

.card {
	font-size: 14px;
	&:not(:last-child) {
		margin-bottom: 10px;
	}
}

.ticker {
	font-weight: bold;
}

.metrics {
	white-space: nowrap;
}

.row1 {
	display: flex;
	justify-content: space-between;
	column-gap: 10px;
}

.value {
	color: @q-gold;
	font-weight: bold;
	margin-left: 8px;
	border-left: solid 1px lighten(@q-gray, 20%);
	padding-left: 8px;
}

.qty {
	font-weight: 500;
}

.props {
	height: 0;
	overflow: hidden;
	transition: 0.2s height;
	margin: 0 -15px 0;
	background: @card-background;

	&_expand {
		height: auto;
	}
}

.propsInner {
	margin-top: 15px;
	border-top: solid 1px @body-background;
	padding: 0 15px;
}

.prop {
	display: flex;
	justify-content: space-between;
	margin-top: 11px;
}

.label {
	color: @q-gold;
}

:global(body.dark) {
	@import '../../../antd-theme-dark.less';

	.props {
		background: @card-background;
	}

	.propsInner {
		border-top: solid 1px @body-background;
	}
}
