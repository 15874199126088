.container {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background: rgba(35, 35, 35, 0.85);
	display: none;

	&_visible {
		display: block;
	}
}

.content {
	bottom: 93px;
	position: absolute;
	width: 100%;
}
